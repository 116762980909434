<template>
  <v-app>
    <v-main style="background: rgba(0,23,74,0.1);">
      <router-view/>
    </v-main>
  </v-app>
</template>

<style>
* {
  font-family: 'Roboto', sans-serif;
}
</style>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
